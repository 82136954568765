import React, { useRef, useEffect } from 'react';

import Lottie from 'lottie-react';
import { useInView } from 'framer-motion';
import styled from 'styled-components';

const MobileLottie = ({ lottie, start, stop }) => {
  const mobileLottieRef = useRef(null);
  const mobilePositionLottieRef = useRef(null);
  const isInView = useInView(mobilePositionLottieRef, { amount: 0.7 });

  useEffect(() => {
    mobileLottieRef.current.goToAndStop(start, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isInView && mobileLottieRef.current.playSegments([start, stop], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <MobileLottieContainer ref={mobilePositionLottieRef}>
      <Lottie
        lottieRef={mobileLottieRef}
        animationData={lottie}
        autoPlay={false}
        loop={false}
        style={{ maxWidth: '500px', width: '100%' }}
      />
    </MobileLottieContainer>
  );
};

export default MobileLottie;

const MobileLottieContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
`;
